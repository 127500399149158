<template>
  <!-- Main Wrapper Start -->
  <div class="main-wrapper">
    <!-- skiptocontent start ( This section for blind and Google SEO, Also for page speed )-->
    <a id="skiptocontent" href="#maincontent">skip navigation</a>
    <!-- skiptocontent End -->

    <!-- Preloader start -->
    <!-- <div class="bee-site-preloader-wrapper">
      <div class="preloder-logo"><img src="@/assets/images/logo.png" alt="w-Beekeeping" /></div>
      <div class="spinner">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div> -->
    <!-- Preloader End -->

    <router-view />

    <bee-footer />
  </div>
  <!-- Main Wrapper end -->

  <!-- Start scroll top -->
  <!-- <div class="scrollup"><i class="fa fa-angle-up"></i></div> -->
  <!-- End scroll top -->
</template>

<script>
// import BeeHeader from '@/components/Header.vue';
import "@/assets/js/owl.carousel.min";
import BeeFooter from "@/components/Footer.vue";

export default {
  name: "App",
  props: ["title"],
  components: {
    BeeFooter,
  },
  mounted() {
    /* scrollTop */
    // eslint-disable-next-line no-undef
    $(".scrollup").on("click", () => {
      // eslint-disable-next-line no-undef
      $("html, body").animate({ scrollTop: 0 }, 600);
      return false;
    });

    /* On scroll */
    // eslint-disable-next-line no-undef
    $(window).on("scroll", function () {
      // eslint-disable-next-line no-undef
      if ($(this).scrollTop() > 40) {
        // eslint-disable-next-line no-undef
        $(".for-sticky").addClass("sticky");
      } else {
        // eslint-disable-next-line no-undef
        $(".for-sticky").removeClass("sticky");
      }
      // eslint-disable-next-line no-undef
      if ($(this).scrollTop() > 300) {
        // eslint-disable-next-line no-undef
        $(".scrollup").fadeIn();
      } else {
        // eslint-disable-next-line no-undef
        $(".scrollup").fadeOut();
      }
    });
  },
};
</script>

<style>
.fade-enter-from {
  opacity: 0;
}

.fade-enter-active {
  transition: all 0.5s linear;
}

.fade-leave-to {
  transition: all 0.5s linear;
  opacity: 0;
}
</style>
