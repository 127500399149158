import { createApp } from "vue";

// eslint-disable-next-line no-unused-vars

// import { auth } from '@/includes/firebase';

import App from "./App.vue";

import router from "./router";
import store from "./store";

import "./assets/css/bootstrap.min.css";
import "./assets/css/font-awesome.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/owl.carousel.css";
import "./assets/css/magnific-popup.css";
import "./assets/css/chat.css";
import "./assets/css/slicknav.min.css";
import "./assets/css/responsive.css";

import "./assets/style.css";

createApp(App)
  .use(store)
  .use(router)

  .mount("#app");

// let app;

// auth.onAuthStateChanged(() => {
//   if (!app) {
//     app = createApp(App);

//     app.use(store);
//     app.use(router);

//     app.mount('#app');
//   }
// });

// const app = '';

// auth.onAuthStateChanged(() => {
//   if (!app) {
//     createApp(App)
//       .use(store)
//       .use(router)
//       .mount('#app');
//   }
// });
