import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAQdvdyBp0lh6pB0DqzuifdfHuLJ7Ig2YA",
  authDomain: "vue-shop-70bd3.firebaseapp.com",
  projectId: "vue-shop-70bd3",
  storageBucket: "vue-shop-70bd3.appspot.com",
  messagingSenderId: "40081257027",
  appId: "1:40081257027:web:b3fc2c55b56796c20a1af6",
  measurementId: "G-LP38R1CSZK",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();
const profilesCollection = db.collection("profiles");
const productsCollection = db.collection("products");
const postsCollection = db.collection("posts");


db.enablePersistence().catch((error) => {
  console.log(`Firebase persistence error ${error.code}`);
});

// const songsCollection = db.collection('songs');
// const commentsCollection = db.collection('comments');

export {
  auth, db, storage, profilesCollection, postsCollection, productsCollection
};
