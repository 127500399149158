/* eslint-disable no-unused-vars */
import { createRouter, createWebHistory } from 'vue-router';
import { auth } from '@/includes/firebase';
import store from '@/store/index';

const Home = () => import('@/views/Home.vue');
const About = () => import('@/views/About.vue');
const News = () => import('@/views/News.vue');
const Blog = () => import('@/views/Blog.vue');
const Article = () => import('@/views/Article.vue');
const Shop = () => import('@/views/Shop.vue');
const Product = () => import('@/views/Product.vue');
const VideoGallery = () => import('@/views/VideoGallery.vue');
const PhotoGallery = () => import('@/views/PhotoGallery.vue');
const Contact = () => import('@/views/Contact.vue');
const Error404 = () => import('@/views/404.vue');
// const Registration = () => import('@/views/auth/Registration.vue');
const Login = () => import('@/views/auth/Login.vue');
const Admin = () => import('@/views/Admin.vue');
const Orders = () => import('@/views/Orders.vue');
const ChangePassword = () => import('@/views/ChangePassword.vue');
const Profile = () => import('@/views/Profile.vue');
const MyCart = () => import('@/views/MyCart.vue');

const Products = () => import('@/views/Products.vue');
const Checkout = () => import('@/views/Checkout.vue');

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    name: 'about',
    path: '/about',
    component: About,
  },
  {
    name: 'news',
    path: '/news',
    component: News,
  },
  {
    name: 'blog',
    path: '/blog',
    component: Blog,
  },
    {
      name: 'article',
      path: '/article/:id',
      component: Article,
    },

  {
    name: 'shop',
    path: '/shop',
    component: Shop,
  },

  {
    name: 'video',
    path: '/video',
    component: VideoGallery,
  },

  {
    name: 'photo',
    path: '/photo',
    component: PhotoGallery,
  },
  {
    name: 'contact',
    path: '/contact',
    component: Contact,
  },
  {
    name: '404',
    path: '/404',
    component: Error404,
  },
  {
    name: 'login',
    path: '/login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (!store.state.userLoggedIn) {
        next();
      } else {
        next({ name: 'admin' });
      }
    },
  },
  {
    name: 'admin',
    path: '/admin',
    meta: {
      requiresAuth: true,
    },
    component: Admin,
  },
  {
    name: 'profile',
    path: '/profile',
    component: Profile,
  },
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
  },
  {
    path: '/changepassword',
    name: 'changepassword',
    component: ChangePassword,
  },
  {
    path: '/mycart',
    name: 'mycart',
    component: MyCart,
  },
  {
    name: 'products',
    path: '/products',
    component: Products,
  },
  {
    name: 'product',
    path: '/product/:id',
    component: Product,
  },
  {
    name: 'checkout',
    path: '/checkout',
    meta: {
      requiresAuth: true,
    },
    
  },
  {
    path: '/:catchAll(.*)*',
    redirect: {
      name: '/404',
    },
  },
];

const router = createRouter({
  base: '/',
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: 'active',
});

router.beforeEach((to, from, next) => {
  // console.log(to.matched);
  if (!to.matched.some((record) => record.meta.requiresAuth)) {
    next();
    return;
  }
  if (store.state.userLoggedIn) {
    next();
  } else {
    next({ name: 'login' });
  }
});
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
  router.replace(targetPath);
  }
  });

export default router;
