<template>
  <!-- Footer start -->
  <footer class="site-footer black-footer">
    <!-- Footer Top start -->
    <div class="footer-top-area wow fadeInUp">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 col-lg-3">
            <div class="footer-wiz">
              <h3 class="footer-logo">
                <img src="../assets/images/footer-logo.png" alt="Bee keeping" />
              </h3>
              <p>
                It was popularised in the 1960s with the release of Letraset sheets containing Lorem
                Ipsum passages.
              </p>
              <ul class="footer-contact">
                <li><i class="fa fa-phone"></i> +88 01911 837404</li>
                <li><i class="fa fa-envelope"></i> info@companyname.com</li>
                <li><i class="fa fa-fax"></i> +88 02 123456</li>
              </ul>
              <div class="top-social bottom-social">
                <a href="#"><i class="fa fa-facebook"></i></a>
                <a href="#"><i class="fa fa-twitter"></i></a>
                <a href="#"><i class="fa fa-youtube"></i></a>
                <a href="#"><i class="fa fa-rss"></i></a>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="footer-wiz footer-menu">
              <h3 class="footer-wiz-title">Quick Links</h3>
              <ul>
                <li><a href="about.html">About Us</a></li>
                <li><a href="product.html">Our Products</a></li>
                <li><a href="product-details.html">Product Details</a></li>
                <li><a href="blog.html">Blog</a></li>
                <li><a href="single-blog.html">Single Blog</a></li>
                <li><a href="news.html">News &amp; Media</a></li>
                <li><a href="video-gallery.html">Video Gallery</a></li>
                <li><a href="photo-gallery.html">Photo Gallery</a></li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="footer-wiz footer-menu">
              <h3 class="footer-wiz-title">Usefull Links</h3>
              <ul>
                <li><a href="my-account.html">My Account</a></li>
                <li><a href="login.html">Login</a></li>
                <li><a href="registration.html">Registration</a></li>
                <li><a href="checkout.html">Checkout</a></li>
                <li><a href="typography.html">Typography</a></li>
                <li><a href="contact.html">Contact Us</a></li>
                <li><a href="sitemap.html">Site map</a></li>
              </ul>
            </div>
          </div>
          <div class="col-12 col-md-6 col-lg-3">
            <div class="footer-wiz">
              <h3 class="footer-wiz-title">Latest News</h3>
              <ul class="latest-news">
                <li>
                  <a href="" title="">when an unknown printer took</a>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                </li>
                <li>
                  <a href="" title="">when an unknown printer took</a>
                  <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry</p>
                </li>
              </ul>
              <a href="" class="all-news">Check All News</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- footer top end -->

    <!-- copyright start -->
    <div class="footer-bottom-area">
      <div class="container">
        <div class="row">
          <div class="col-12 col-lg-6 wow fadeInLeft">
            Copyright © 2018 <span>Wrold BeeKeeping</span>. All Rights Reserved
          </div>
          <div class="col-12 col-lg-6 text-right wow fadeInRight">
            Powered By:
            <a href="http://web24service.com/" title="web24service" target="_blank">As-Storitve</a>
          </div>
        </div>
      </div>
    </div>
    <!-- copyright end -->
  </footer>
  <!-- Footer end -->
</template>

<script>
export default {
  name: "Footer",
};
</script>
