import { createStore } from "vuex";
import { auth,db, profilesCollection } from "@/includes/firebase";
import helpers from "@/includes/helpers";

const cart = window.localStorage.getItem("cart");
const userLoggedIn = window.localStorage.getItem("authUser");

export default createStore({
  state: {
    userLoggedIn: userLoggedIn ? JSON.parse(userLoggedIn) : false,
    cart: cart ? JSON.parse(cart) : [],
  },
  mutations: {
    addToCart(state, item) {
      const found = state.cart.find((product) => product.productId === item.productId);

      if (found) {
        found.productQuantity += 1;
      } else {
        state.cart.push(item);
      }
      this.commit("saveData");
    },
    toggleAuth(state) {
      state.userLoggedIn = !state.userLoggedIn;
    },

    saveData(state) {
      window.localStorage.setItem("cart", JSON.stringify(state.cart));
      
    },
    removeFromCart(state, item) {
      const index = state.cart.indexOf(item);

      state.cart.splice(index, 1);
      this.commit("saveData");
    },
    authUser(state) {
      window.localStorage.setItem("authUser", JSON.stringify(state.userLoggedIn));
    },
    
  },
  actions: {
    async login({ commit }, payload) {
      await auth.signInWithEmailAndPassword(payload.email, payload.password);

      commit("toggleAuth");
      commit("authUser");
    },

    async register({ commit }, payload) {
      
      const userCred = await auth.createUserWithEmailAndPassword(
        payload.email, payload.password,
      );

      await profilesCollection.doc(userCred.user.uid).set({
        name: payload.firstName,
        email: payload.email,
        uid: userCred.user.uid

      });
      
     
      await userCred.user.updateProfile({
        displayName: payload.name,
      });

      commit("toggleAuth");
    },
    async signout({ commit }) {
      await auth.signOut();

      commit("toggleAuth");
      window.localStorage.removeItem("authUser");
    },

  },
  getters: {
    totalPrice: (state) => {
      let total = 0;
      state.cart.forEach((item) => {
        total += (item.productPrice * item.productQuantity);
      });

      return helpers.withDecimalAndCurrency(total);
    },
  },

  modules: {
  },
});
